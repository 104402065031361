<template>
  <v-container fluid>
    <TheTitle :text="title" :icon="pageIcon">
      <v-row justify="center">
        <v-col>
          <v-text-field
            placeholder="Procurar"
            dense
            solo
            background-color="#FFFFFF"
            hide-details
            v-model="search"
          ></v-text-field>
        </v-col>
      </v-row>
    </TheTitle>

    <v-card class="pa-5 full-height">
      <v-data-table :headers="header" :items="objects" dense locale="pt-BR" :search="search" :items-per-page="30">
        <template v-slot:[`item.status`]="{ item }">
          <v-chip label x-small dark color="primary" v-if="item.status">Ativo</v-chip>
          <v-chip label x-small dark color="red" v-if="!item.status">Inativo</v-chip>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <TheToolTip label="Editar">
            <v-btn icon small tile>
              <v-icon @click="dialogUpdate(item)">mdi-pen</v-icon>
            </v-btn>
          </TheToolTip>
          <TheToolTip label="Remover">
            <v-btn icon small tile>
              <v-icon @click="remove(item.id)">mdi-minus</v-icon>
            </v-btn>
          </TheToolTip>
        </template>
      </v-data-table>
    </v-card>

    <TheDialog v-model="dialog" :title="title" :icon="pageIcon" @close-dialog="loadData">
      <template>
        <v-form v-model="valid" ref="form">
          <div class="mb-5">
            <b>Empresa</b> <br />
            {{ object.name }}
          </div>
          <div class="mb-5">
            <b>Plano</b>
            <v-select
              placeholder="Plano"
              v-model="object.idSubscriptionPlan"
              :items="plans"
              item-value="id"
              item-text="name"
              class="my-2"
              dense
              hide-details
              outlined
            ></v-select>
          </div>
          <div class="mb-n1">
            <b>Status</b>
            <v-radio-group v-model="object.status">
              <v-radio label="Ativo" :value="true"></v-radio>
              <v-radio label="Inativo" :value="false"></v-radio>
            </v-radio-group>
          </div>
          <div class="mb-n0">
            <v-row>
              <v-col md="6" sm="12" cols="12">
                <b>Validade</b>                
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="object.validAt"
                      @input="date = parseDate(object.validAt)"
                      prepend-inner-icon="mdi-calendar"
                      placeholder="Data"
                      class="mt-2"
                      persistent-hint
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                      dense
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :rules="requiredRule"
                    v-model="date"
                    no-title
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </div>
        </v-form>
      </template>
      <template v-slot:actions="">
        <v-btn small depressed color="primary" class="mt-2" dark @click="resolveForm()">
          <v-icon small class="mr-2">mdi-plus</v-icon>Salvar
        </v-btn>
        <v-spacer></v-spacer>
        <span class="ml-5 caption"><sup>*</sup>Campos obrigatórios</span>
      </template>
    </TheDialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import { baseApiUrl, showError } from '@/global'
import TheTitle from '@/components/TheTitle'
import TheDialog from '@/components/TheDialog'
import TheToolTip from '@/components/TheToolTip'
import { mask } from 'vue-the-mask'
import moment from 'moment'

export default {

  name: 'Locais',

  components: {
    TheTitle,
    TheDialog,
    TheToolTip,
  },

  directives: {
    mask,
  },

  data: () => ({

    baseRoute: 'subscription',
    title: 'Gerência de Assinaturas',
    pageIcon: 'mdi-google-maps',

    object: {},
    plans: [],
    objects: [],
    
    menu1: false,
    date: null,

    dialog: false,
    search: null,
    valid: false,
    error: false,
    
    header: [
      { text: 'Nome', value: 'name' },
      { text: 'CNPJ', value: 'document' },
      { text: 'Plano', value: 'subscriptionPlanName' },
      { text: 'Status', value: 'status', align:"center" },
      { text: 'Valido Até', value: 'validAt' },
      { text: 'Ações', value: 'action', width: '10%' },
    ],
    
  }),

  methods: {

    loadData() {
      this.$store.dispatch('setLoadingInfo', true)
      const url = `${baseApiUrl}/${this.baseRoute}`
      axios
        .get(url)
        .then((res) => {
          this.$store.dispatch('setLoadingInfo', false)
          this.objects = res.data
        })
        .catch(showError)
    },

    loadPlan() {
      this.$store.dispatch('setLoadingInfo', true)
      const url = `${baseApiUrl}/subscriptionPlan`
      axios
        .get(url)
        .then((res) => {
          this.$store.dispatch('setLoadingInfo', false)
          this.plans = res.data
        })
        .catch(showError)
    },
    
    dialogUpdate(item) {
      this.object = item
      this.object.status == 1 ? this.object.status = true : this.object.status = false
      this.edit = true
      this.dialog = true
    },

    resolveForm() {
      this.$refs.form.validate()
      if (!this.valid) {
        this.$store.dispatch('setErrorInfo', {
          open: true,
          text: 'Os campos não foram preenchidos corretamente, você pode verificar?',
          button: 'Ok!',
        })
        return
      }
      this.update()
    },

    update() {
      this.error = false

      const url = `${baseApiUrl}/${this.baseRoute}`
      const objects = this.object

      if (objects.id) {
        objects.id = parseInt(objects.id)
      } else {
        delete objects.id
      }
      if (objects.idCompany) {
        objects.idCompany = parseInt(objects.idCompany)
      }

      delete objects.name
      delete objects.document
      delete objects.subscriptionPlanName
      delete objects.createdAt
      delete objects.updatedAt
      delete objects.deletedAt
      axios
        .post(url, objects)
        .then(() => this.loadData())
        .catch(showError)
        .finally(() => {
          this.dialog = false
        })
    },

    remove(id) {
      if (confirm('Remove?')) {
        const url = `${baseApiUrl}/${this.baseRoute}/${id}`
        axios
          .delete(url)
          .then(() => this.loadData())
          .catch(showError)
      }
    },

    parseDate(date) {
      if (!date) return null
      const [month, day, year] = date.substring(0, 11).split("/")
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
    },
  },

  watch: {
    date() {
      this.object.validAt = this.date
      this.object.validAt = moment(this.object.validAt).format('DD/MM/YYYY')
    },
  },

  mounted() {
    this.loadData()
    this.loadPlan()
  },
 
}
</script>

<style>
.full-height {
  height: 80vh;
}
</style>